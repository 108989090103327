
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserData } from '@/store/layout-store/types';
import {
  NeIcon
} from '@ne/ne-vue2-lib';
import { RouteName } from '@/router/models';
import { CookieName } from '@/core/cookies';

@Component({
  name: 'AppToolbarUserProfile',
  components: {
    NeIcon
  }
})
export default class AppToolbarUserProfile extends Vue {
  @Prop() user: UserData;
  @Prop() isAuthDevMode: boolean;

  get profileUrl (): string {
    const apiUrl: URL = new URL(this.user.urlProfile);
    // hack for watch route change
    const routeWatcher: string = this.$route.fullPath.substring(0, 0);
    const redirectUri = `${window.location.href}${routeWatcher}`;
    apiUrl.searchParams.set('redirect_uri', encodeURI(redirectUri));
    apiUrl.searchParams.set('change', '1');
    return apiUrl.toString();
  }

  logout (): void {
    if (this.isAuthDevMode) {
      this.$router.push({ name: RouteName.LOGOUT });
    } else {
      this.$cookies.remove(CookieName.USER_TOKEN);
      this.$cookies.remove(CookieName.USER_HASH);
      window.location.assign(this.user.urlLogout);
    }
  }
}
