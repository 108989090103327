
import { Component, Vue } from 'vue-property-decorator';
import { FOOTER } from '@/store/list';
import { FooterModel } from '@/store/layout-store/types';
import { namespace } from 'vuex-class';
import {
  NeFooter,
  NeFooterSection
} from '@ne/ne-vue2-lib';

const LayoutStore = namespace('LayoutStore');
@Component({
  name: 'AppFooter',
  components: {
    NeFooter,
    NeFooterSection
  }
})
export default class GtFooter extends Vue {
  @LayoutStore.Getter(FOOTER) footer: FooterModel[];

  get copyrightYearRange (): string {
    const currentYear = new Date().getFullYear();
    return `2017–${currentYear}`;
  }
}
