
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AppFooter from './AppFooter';
import AppNotificator from './AppNotificator';
import { SET_HEADER, USER_NOTIFY, USER_DATA, SET_LOGIN_STATUS } from '@/store/list';
import { Lang, LayoutModel, UserData } from '@/store/layout-store/types';
import { Notification, NotificationConfig, LoginStatus } from '@/models';
import { CookieName } from '@/core/cookies';
import { LogoutStatus } from '@/models/auth';
import AppToolbarLogged from '@/layouts/App/AppToolbarButtons/AppToolbarLogged.vue';

const AppStore = namespace('AppStore');
const LayoutStore = namespace('LayoutStore');

enum LoginText {
  VERIFICATION_IN_PROGRESS = 'user_verification',
  SUCCESS = 'login_success',
  NOT_VERIFIED = 'missing_permissions'
}

@Component({
  components: {
    AppToolbarLogged,
    AppFooter,
    AppNotificator
  }
})
export default class App extends Vue {
  isHeaderFetched = false;

  @LayoutStore.Getter(USER_DATA) user: UserData | null;

  get isUserLogged (): boolean {
    return !!this.user;
  }

  @LayoutStore.Action(SET_HEADER) setHeader: () => Promise<LayoutModel>;
  @AppStore.Action(USER_NOTIFY) notify: (data: NotificationConfig) => Promise<Notification>;
  @AppStore.Mutation(SET_LOGIN_STATUS) setLoginStatus: (status: LoginStatus) => Promise<void>;

  async created (): Promise<void> {
    localStorage.removeItem('activeFacets');
    await this.setHeader();
    this.$i18n.locale = this.user?.lang ?? Lang.PL;
    this.isHeaderFetched = true;
    this.checkLoginStatus();
    this.checkLogoutStatus();
    this.checkAccessStatus();
  }

  checkLoginStatus (): void {
    const loginStatus = sessionStorage.getItem(CookieName.LOGIN_STATUS);
    sessionStorage.removeItem(CookieName.LOGIN_STATUS);
    if (loginStatus) {
      const status = parseInt(loginStatus);
      this.setLoginStatus(status);
      const textKey = status === LoginStatus.SUCCESS
        ? LoginText.SUCCESS
        : status === LoginStatus.VERIFICATION_IN_PROGRESS
          ? LoginText.VERIFICATION_IN_PROGRESS
          : LoginText.NOT_VERIFIED;
      let message: string = this.$tc(`AUTH.${textKey}`);
      const messageLinkText: string = 'kontakt';
      const contactPagePath = 'https://dlanauczyciela.pl/kontakt';
      message = message.split('###CONTACT_LINK###').join(contactPagePath ? `<a href="${contactPagePath}">${messageLinkText}</a>` : messageLinkText);
      this.notify({
        msg: message,
        type: status === LoginStatus.SUCCESS ? 'success' : 'info',
        permanent: status === LoginStatus.NOT_VERIFIED
      });
    }
  }

  checkLogoutStatus (): void {
    const logoutStatus = this.$cookies.get(CookieName.LOGOUT_STATUS);
    this.$cookies.remove(CookieName.LOGOUT_STATUS);
    if (logoutStatus) {
      if (logoutStatus === LogoutStatus.SUCCESS) this.notify({ msg: this.$tc('AUTH.logout_success'), type: 'success' });
      if (logoutStatus === LogoutStatus.SESSION_EXPIRED) this.notify({ msg: this.$tc('AUTH.logout_session_expired'), type: 'error' });
      if (logoutStatus === LogoutStatus.ERROR) this.notify({ msg: this.$tc('AUTH.logout_error'), type: 'error' });
    }
  }

  checkAccessStatus (): void {
    const accessStatus = sessionStorage.getItem('access_status');
    sessionStorage.removeItem('access_status');
    if (accessStatus === 'denied') {
      const notification: NotificationConfig = {
        msg: this.$tc('NOTIFICATIONS.access_denied'),
        type: 'info',
        timeInMs: 5000
      };
      this.notify(notification);
    }
  }
}
