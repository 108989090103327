
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NeIcon, NeToolbarMenuDropdown, NeAppWrapper } from '@ne/ne-vue2-lib';
import { RouteName, RoutePath } from '@/router/models';
import AppToolbarUserProfile from '@/layouts/App/AppToolbar/AppToolbarUserProfile/AppToolbarUserProfile.vue';
import { UserData } from '@/store/layout-store/types';
import { namespace } from 'vuex-class';
import { CookieName } from '@/core/cookies';
import { LoginStatus } from '@/models';
import { ROLES } from '@/config';
import { isSuperUser } from '@/helpers/roles';

const AppStore = namespace('AppStore');

@Component({
  name: 'AppToolbarLogged',
  components: {
    NeIcon,
    NeToolbarMenuDropdown,
    NeAppWrapper,
    AppToolbarUserProfile
  }
})
export default class AppToolbarLogged extends Vue {
  @Prop() user: UserData | null;
  @Prop() isUserLogged: boolean;

  routeName = RouteName;
  routePath = RoutePath;
  isUserProfileActive = false;

  LoginStatus = LoginStatus;

  @AppStore.State('loginStatus') loginStatus: LoginStatus;
  @AppStore.State('isAuthDevMode') isAuthDevMode: boolean;
  @AppStore.State('userRole') userRole: ROLES[];

  get isRedactorOrAdmin (): boolean {
    return isSuperUser(this.userRole);
  }

  goHome (): void {
    if (this.$route.name !== RouteName.HOME) this.$router.push({ name: RouteName.HOME });
  }

  login (): void {
    if (this.isAuthDevMode) {
      sessionStorage.setItem(CookieName.LOGIN_RETURN_PATH, this.$route.fullPath);
      this.$router.push({ name: RouteName.LOGIN });
    } else {
      window.location.href = RoutePath.LOGIN;
    }
  }

  register (): void {
    if (this.isAuthDevMode) {
      this.$router.push({ name: RouteName.REGISTER });
    } else {
      window.location.href = RoutePath.REGISTER;
    }
  }
}
