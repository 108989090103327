import { ROLES } from '@/config';

export const isRedactorGroup = (roles: ROLES[]): boolean => {
  return roles.includes(ROLES.REDACTOR_EXAM) || roles.includes(ROLES.REDACTOR_DLU) || roles.includes(ROLES.REDACTOR_GT) || roles.includes(ROLES.REDACTOR_NSML);
};

export const isTeacherGroup = (roles: ROLES[]): boolean => {
  return roles.includes(ROLES.TEACHER) || roles.includes(ROLES.GUEST);
};

export const isRedactorExam = (roles: ROLES[]): boolean => {
  return roles.includes(ROLES.REDACTOR_EXAM);
};

export const isRedactorNsml = (roles: ROLES[]): boolean => {
  return roles.includes(ROLES.REDACTOR_NSML);
};

export const isSuperUser = (roles: ROLES[]): boolean => {
  return roles.includes(ROLES.ADMIN) || isRedactorGroup(roles);
};

export const isAdmin = (roles: ROLES[]): boolean => {
  return roles.includes(ROLES.ADMIN);
};

export const isPublisher = (roles: ROLES[]): boolean => {
  return roles.includes(ROLES.PUBLISHER) && isSuperUser(roles);
};

export const isRedactorDlu = (roles: ROLES[]): boolean => {
  return roles.includes(ROLES.REDACTOR_DLU);
};

export const isConsumerRole = (roles: ROLES[]): boolean => {
  return isRedactorExam(roles) || isRedactorDlu(roles) || isRedactorNsml(roles);
};

export const isRedactorExamOrNsml = (roles: ROLES[]): boolean => {
  return isRedactorExam(roles) || isRedactorNsml(roles);
};
