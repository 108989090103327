
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { REMOVE_NOTIFICATION } from '@/store/list';
import { Notification, NotificationType } from '@/models';
import { NeIcon } from '@ne/ne-vue2-lib';
const AppStore = namespace('AppStore');

@Component({
  name: 'AppNotificator',
  components: { NeIcon }
})
export default class AppNotificator extends Vue {
  @AppStore.State('notifications') notifications!: Notification[];

  @AppStore.Mutation(REMOVE_NOTIFICATION) removeNotification: (n: Notification | null) => void;

  getNotificationClass (type: NotificationType): string {
    switch (type) {
    case 'success':
      return 'check-2';
    case 'error':
      return 'cancel-circle';
    case 'info':
      return 'info';
    case 'warning':
      return 'info';
    }
  }
}
