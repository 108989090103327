import { StructureMappingItem, TreeNode } from '@/models/structure-mapping';
import { ROLES } from '@/config';
import { TestTypeIdEnum } from '@/models';

export interface FooterModel {
  title: string;
  url?: string;
  links: FooterLink[];
}

export interface FooterLink {
  name: string;
  id: string;
  url: string;
  target: string | null;
}

export interface MenuItem {
  id: number | string;
  name: string;
  displayName: string | null;
  url: string;
  parentId: number | string | null;
  sectionName?: string | null;
  neCode?: string | number | null;
  className?: string | null;
  visibleLabel?: number | string | null;
  clickedTab?: boolean;
  children?: SecondLevelChildren;
  categoryKey?: string;
  defaultClass?: any;
}
export interface TabItem extends MenuItem {
  others?: Array<MenuItem>;
  my?: Array<MenuItem>;
}

export interface SecondLevelChildren {
  [key: number]: MenuItem | undefined;
}

export interface FirstLevelChildren {
  [key: number]: TabItem | undefined;
}

export interface HeaderModel {
  id: number | string;
  name: string;
  invisibleName?: string | number | null;
  order?: number | null;
  class?: string | null;
  active?: number | null;
  children: FirstLevelChildren;
}

export interface Logo {
  name: string;
  title: string | null;
  url: string;
}

export interface ClassList {
  class: string;
  id: string | number;
  label?: string;
  name: string;
  categoryKey: string;
  mapId?: number;
  newStructure: boolean;
  mapCategoryId: number | null;
}

interface Tab {
  class: string;
  categoryKey: string;
  name: string;
  helpVideo: string[];
}

export interface StructureItem {
  name: string;
  categoryKey: string;
}

export interface MenuModel {
  text: string | null;
  logo: Logo;
  class: Array<ClassList>;
  mapCategory: Array<StructureItem>;
  newStructure: TreeNode[] | null;
  structureMapping: StructureMappingItem[];
  newSeriesMap: NewSeriesMap | [];
}

export interface NewSeriesMap {
  id: string;
  name: string;
  key: string;
}

export enum Lang {
  EN = 'en',
  PL = 'pl',
}

export interface UserData {
  copyright?: string;
  email: string;
  firstLogin: boolean;
  id: number;
  isOndorio: boolean;
  name: string;
  role: ROLES[];
  urlLogout: string;
  urlProfile: string;
  lang: Lang;
}

export interface ApiMessage {
  message: string;
  type: 'success' | 'error' | 'info' | null;
  vars: any[];
}

export interface LayoutModel {
  messenger: ApiMessage[];
  footer: FooterModel[] | null;
  header: HeaderModel | null;
  user: UserData | null;
}

export interface LayoutState {
  layout: LayoutModel;
  menu: MenuModel | null;
}

export interface SubjectMessage {
  categoryKey: string;
  image: string;
  cookie: string;
  title: string;
  descriptionItems: string[];
}

export interface MenuLogo {
  title: string;
  url: string;
}

export interface StartPage {
  class: Menu[];
  testType: TestTypeItem;
}
export type TestTypeItem = {
  [key in TestTypeIdEnum]?: string;
}
export interface Menu {
  key: string;
  earlySchoolEducation: boolean;
  name: string;
  newStructure?: boolean;
  logo?: MenuLogo;
  access: boolean;
  label?: string | null;
  id?: number;
  mapId?: number | null;
  children?: Menu[];
  mapCategoryId?: number | null;
}
